import React from 'react'
import {Button, Popover} from "antd";
import {connect} from "react-redux";
import * as LoginActions from './LoginActions'

const LogoutPanel = ({logins, dispatch}) => {
    return <div style={{marginRight: 8}}>
        {/*<Popover content={(*/}
        {/*    <div>*/}
        {/*        <Button size={'small'}>Hello</Button>*/}
        {/*    </div>*/}
        {/*)}>*/}
        {/*    <Button onClick={()=>{*/}
        {/*        dispatch(LoginActions.logoutAll());*/}
        {/*    }}>Abmelden</Button>*/}
        {/*</Popover>*/}
        <Button onClick={()=>{
            dispatch(LoginActions.logoutAll());
        }}>Abmelden</Button>
    </div>
}

export default connect(state => ({
    logins: state.login.logins.map(id => state.login.entities.logins[id]),
}))(LogoutPanel);
