import React from 'react'
import {Icon, Menu} from "antd";
import FoldersMenu from "../Folders/FoldersMenu";

const {SubMenu} = Menu;

const MainMenu = () => (
    <div>
        <FoldersMenu/>

        {/*<Menu*/}
        {/*    mode="inline"*/}
        {/*    onClick={(e) => {*/}
        {/*        console.log("Clock menu", e)*/}
        {/*    }}*/}
        {/*    openKeys={[*/}
        {/*        'own-folders', 'shared-folders'*/}
        {/*    ]}*/}
        {/*    onOpenChange={() => {*/}
        {/*        console.log("onPpenChange")*/}
        {/*    }}*/}
        {/*>*/}
        {/*    */}

        {/*</Menu>*/}
    </div>
)

export default MainMenu;
