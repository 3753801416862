import React from 'react'
import {Icon} from 'antd'

const FileIcon = ({type, theme = ''}, props) => {
    switch(type){
        case 'application/pdf':
            return <Icon type={'file-pdf'} theme={theme}/>
        case 'image/jpg':
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
            return <Icon type={'file-image'} theme={theme}/>
        case 'application/zip':
        case 'application/gzip':
            return <Icon type={'file-zip'} theme={theme}/>
        case 'application/msexcel':
        case 'text/csv':
            return <Icon type={'file-excel'} theme={theme}/>
        case 'application/msword':
            return <Icon type={'file-word'} theme={theme}/>
        case 'application/mspowerpoint':
            return <Icon type={'file-ppt'} theme={theme}/>
        case 'text/plain':
        case 'text/css':
        case 'text/html':
        case 'text/comma-separated-values':
        case 'text/richtext':
        case 'text/rtf':
        case 'text/xml':
        case 'application/rtf':
            return <Icon type={'file-text'} theme={theme}/>
        default:
            return <Icon type={'file'} theme={theme}/>



    }
};


export default FileIcon;
