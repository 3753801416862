import * as FoldersActions from './FoldersActions'
import * as FolderActions from '../FolderContent/FolderActions'

import dotprop from 'dot-prop-immutable'
import {normalize, schema} from 'normalizr'

const initialState = {
    entities: {
        folders: {},
    },
    result: [],
    folderLogins: {},
    openFolder: {
        current: undefined,
        open: [],
    },
    open: undefined,
};


// const loginSchema = new schema.Object({});
export const folder = new schema.Entity('folders', {}, {
    processStrategy: (entity, parent) => ({
        ...entity,
        parent: parent.id,
        loginId: entity.loginId ? entity.loginId : parent.loginId
    }),
});
const folders = new schema.Array(folder);
folder.define({
    children: folders,
    parent: folder,
});

function findParents(folders, id, res = []) {
    let newId = folders[id].parent;
    if (!newId)
        return [id, ...res];
    return findParents(folders, newId, [id, ...res])
}

export default function FoldersReducer(state = initialState, action) {

    switch (action.type) {

        case FoldersActions.OPEN_FOLDER: {
            let {id} = action;
            return {
                ...state,
                open: id,
            }
        }
        case FoldersActions.LOAD_CHILDREN_DONE: {
            let {parentId, children} = action;

            // let newState = state;

            let parent = state.entities.folders[parentId];
            console.log("loadChildrenParent", parent)


            // children = children.map(c => ({
            //     ...c,
            //     parent: parent,
            // }))
            let norm = normalize(children.map(c => ({...c, loginId: parent.loginId})), [folder]);
            console.log("Normalized childs", norm);

            // let newState = state;
            let newState = dotprop.merge(state, 'entities.folders', norm.entities.folders);
            // newState = dotprop.set(newState, 'result', newState.result.concat(norm.result.filter(i => newState.result.indexOf(i) < 0)));

            // let newState = dotprop.set(state, `entities.folders.${parentId}.children`, children.map(c => c.id));
            // newState = dotprop.set(newState, `entities.folders.${parentId}.children`, children.map(c => c.id));
            return newState;
        }
        case FoldersActions.LOAD_FOLDER_DONE: {

            let {loginId, data} = action;

            data = data.map(d => ({...d, loginId}));

            // data = data.map(d => ({...d, loginId}));
            let norm = normalize(data, [folder]);

            // let folderLogins = Object.keys(norm.entities.folders).reduce((res, folderKey) => {
            //     res[folderKey] = loginId;
            //     return res;
            // }, {});

            let newState = dotprop.merge(state, 'entities.folders', norm.entities.folders);
            newState = dotprop.set(newState, 'result', newState.result.concat(norm.result.filter(i => newState.result.indexOf(i) < 0)));
            // newState = dotprop.merge(newState, 'folderLogins', folderLogins);
            return newState;
        }
    }

    return state;
}

