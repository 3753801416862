import React from 'react'
import Head from "./Basic/Head";
import './MainLayout.less'
import MainMenu from "./Basic/MainMenu";
import LogoutPanel from "./Login/LogoutPanel";

const MainLayout = ({children}) => (
    <div className={'drive'}>
        <header className={'header'}>
            <Head/>
            <div style={{flex: 1}}/>
            <LogoutPanel/>
        </header>
        <div className={'columns'}>
            <div className={'side'}>
                <MainMenu/>
            </div>
            <div className={'content'}>
                {children}
            </div>
        </div>
        {/*<footer>*/}
        {/*    Copyright &copy; 2020 by envago GmbH*/}
        {/*</footer>*/}
    </div>
)

export default MainLayout;
