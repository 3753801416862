export const LOGIN = 'LOGIN'
export const login = (loginType, login) => ({type: LOGIN, login, loginType});

export const LOGIN_OAUTH = 'LOGIN_OAUTH'
export const loginOauth = () => ({type: LOGIN_OAUTH,});


export const RESTORE_LOGIN_DONE = 'RESTORE_LOGIN_DONE';
export const restoreLogin = (logins) => ({
    type: RESTORE_LOGIN_DONE, logins,
})


export const LOGIN_EXTRACTED = 'LOGIN_EXTRACTED';
export const loginExtracted = (loginType, login) => ({type: LOGIN_EXTRACTED, login, loginType});

export const NO_LOGIN_EXTRACTED = 'NO_LOGIN_EXTRACTED';
export const noLoginExtracted = () => ({type: NO_LOGIN_EXTRACTED});


export const LOGIN_REQUIRED = 'LOGIN_REQUIRED';
export const loginRequired = () => ({type: LOGIN_REQUIRED});

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const loginSuccess = () => ({type: LOGIN_SUCCESS});

// export const LOGIN_FAILED = 'LOGIN_FAILED';
// export const loginFailed = () => ({type: LOGIN_SUCCESS});


export const LOGOUT_ALL = 'LOGOUT_ALL';
export const logoutAll = () => ({type: LOGOUT_ALL,});

export const LOGOUT = 'LOGOUT';
export const logout = (loginId) => ({type: LOGOUT, loginId,});
