import {put, take, takeLatest, call, select} from 'redux-saga/effects'
import axios from 'axios'
import * as FoldersActions from "./FoldersActions";
import * as FolderActions from "../FolderContent/FolderActions";
import {useEffect} from "react";
import {getAxiosInstance} from "../Login/LoginSaga";
import {baseUrl} from "../../Const";



export function* getFolderLoginId(folderId){
    return yield select(state => state.folders.entities.folders[folderId].loginId);
}

export function* loadFolder(loginId) {

    let axiosInstance = yield call(getAxiosInstance, loginId);
    let res = yield axiosInstance.get(`${baseUrl}/api/bucket/`, {

    });
    yield put(FoldersActions.loadFolderDone(loginId, res.data));


    return res.data;
}


function* createFolder({parentId, name, description, defaultRetentionTime}){
    let loginId = yield getFolderLoginId(parentId);
    let axiosInstance = yield getAxiosInstance(loginId);

    let res = yield axiosInstance.post(`${baseUrl}/api/bucket/${parentId}`, {
        name, description, retentionSettings: {
            defaultRetentionTime: defaultRetentionTime
        }
    }, {

    });
    console.log('res', res);
    yield loadFolder(loginId);
}

function* loadChildren({id}) {
    console.log("saga load children for", id);


    try {
        let loginId = yield getFolderLoginId(id);
        let axiosInstance = yield getAxiosInstance(loginId);
        let res = yield axiosInstance.get(`${baseUrl}/api/bucket/${id}`);

        // if(res.data.length > 0){
        //     let parent = res.data.find(e => e.id === id);
        //     console.log("FOUND PARENT", parent);
        //     yield put(FoldersActions.loadChildrenDone(id, parent.children));
        // }

        console.log("LoadChildren Data", res.data);

        yield put(FoldersActions.loadChildrenDone(id, res.data));
    } catch(e){
        console.log("Failed to load children ")
    }

}

export function* foldersSaga() {
    // yield takeLatest(FoldersActions.LOAD_FOLDER, loadFolder);
    yield takeLatest(FoldersActions.CREATE_FOLDER, createFolder);

    yield takeLatest(FoldersActions.OPEN_FOLDER,  loadChildren);

    // yield put(FoldersActions.loadFolder());


    // yield axios.get('http://192.168.1.59:8083/api/jobs', {
    //     headers: {
    //         'X-API-Key': '123',
    //         'Authorization': 'Basic NjA1NzcyNzg2OjA0MjI5'
    //     },
    //
    // })

}
