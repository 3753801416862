export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const downloadFile = (id, name, loginId) => ({type: DOWNLOAD_FILE, id, name, loginId})


export const DOWNLOAD_FILE_START = 'DOWNLOAD_FILE_START';
export const downloadFileStart = (id) => ({type: DOWNLOAD_FILE_START, id})

export const DOWNLOAD_PROGRESS = 'DOWNLOAD_PROGRESS';
export const downloadProgress = (id, progress, total) => ({type: DOWNLOAD_PROGRESS, id, progress, total});

export const DOWNLOAD_FILE_DONE = 'DOWNLOAD_FILE_DONE';
export const downloadFileDone = (id) => ({type: DOWNLOAD_FILE_DONE, id});

export const DOWNLOAD_CANCEL = 'DOWNLOAD_CANCEL';
export const downloadCancel = (id) => ({type: DOWNLOAD_CANCEL, id});


export const UPLOAD_FILE = 'UPLOAD_FILE';
export const uploadFile = (file, fileId, folderId, loginId) => ({type: UPLOAD_FILE, name: file.name, file, id: fileId, folderId, loginId});


export const UPLOAD_FILE_START = 'UPLOAD_FILE_START';
export const uploadFileStart = (id) => ({type: UPLOAD_FILE_START, id})

export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const uploadProgress = (id, progress, total) => ({type: UPLOAD_PROGRESS, id, progress, total});

export const UPLOAD_CANCEL = 'UPLOAD_CANCEL';
export const uploadCancel = (id) => ({type: UPLOAD_CANCEL, id});

export const UPLOAD_FILE_DONE = 'UPLOAD_FILE_DONE';
export const uploadFileDone = (id) => ({type: UPLOAD_FILE_DONE, id});
