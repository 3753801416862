import * as DownloadActions from './DownloadActions'
import dotprop from 'dot-prop-immutable'
import {normalize, schema} from 'normalizr'

const initialState = {
    downloads: [],
    entities: {},
};


export default function DownloadReducer(state = initialState, action) {
    switch (action.type) {
        case DownloadActions.DOWNLOAD_CANCEL: {
            let {id} = action;
            return {
                ...state,
                entities: dotprop.delete(state.entities, id),
                downloads: state.downloads.filter(d => d !== id),
            }
        }
        case DownloadActions.UPLOAD_FILE_DONE:
        case DownloadActions.DOWNLOAD_FILE_DONE: {
            let {id} = action;
            return {
                ...state,
                entities: dotprop.delete(state.entities, id),
                downloads: state.downloads.filter(d => d !== id),
                // entities: dotprop.merge(state.entities, id, {
                //     done: true,
                // }),
            }
        }
        case DownloadActions.UPLOAD_PROGRESS:
        case DownloadActions.DOWNLOAD_PROGRESS: {
            let {id, progress, total} = action;
            return {
                ...state,
                entities: dotprop.merge(state.entities, id, {
                    progress,
                    total,
                }),
            }
        }
        case DownloadActions.UPLOAD_FILE:
        case DownloadActions.DOWNLOAD_FILE: {
            let {id, name} = action;
            return {
                ...state,
                entities: dotprop.merge(state.entities, id, {
                    name: name,
                    id: id,
                    done: false,
                }),
                downloads: [...state.downloads.filter(d => d !== id), id],
            }
        }
    }
    return state;
}


