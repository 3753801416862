import * as FileDownloadActions from './FileDownloadActions'
import dotprop from 'dot-prop-immutable'


const initialState = {
    failed: false,
};


export default function FileDownloadReducer(state = initialState, action) {
    switch (action.type) {
        case FileDownloadActions.FILE_DOWNLOAD_DOWNLOAD: {
            return {
                ...state,
                failed: false,
            }
        }
        case FileDownloadActions.FILE_DOWNLOAD_FAILED: {
            return {
                ...state,
                failed: true,
            }
        }
    }
    return state;
}


