import {put, fork} from 'redux-saga/effects'
import {folderSaga} from "../Screens/FolderContent/FolderSaga";
import {foldersSaga} from "../Screens/Folders/FoldersSaga";
import downloadSaga from "../Screens/FolderContent/DownloadSaga";
import shareSaga from "../Screens/FolderContent/ShareSaga";
import loginSaga from "../Screens/Login/LoginSaga";
import {fileDownloadSaga} from "../Screens/FileDownload/FileDownloadSaga";


export function* rootSaga() {
    yield put({type: 'INIT'})


    yield fork(folderSaga);
    yield fork(foldersSaga);
    yield fork(downloadSaga);
    yield fork(shareSaga);
    yield fork(loginSaga);
    yield fork(fileDownloadSaga);
}
