import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import createStore from './Store'
import {ConnectedRouter} from "connected-react-router";
import {rootSaga} from "./Store/Sagas";

const [store, history] = createStore();


store.runSaga(rootSaga)

ReactDOM.render((
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App/>
        </ConnectedRouter>
    </Provider>
), document.getElementById('root'));


if (module.hot) {
    module.hot.accept("./App", () => {
        const NextApp = require("./App").default;
        ReactDOM.render(<Provider store={store}>
            <ConnectedRouter history={history}>
                <NextApp/>
            </ConnectedRouter>
        </Provider>, document.getElementById('root'));
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
