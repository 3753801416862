import React, {useEffect} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import './style.less'
// import * as DownloadActions from '../FolderContent/DownloadActions'
import * as FileDownloadActions from './FileDownloadActions'
import {Progress, Spin} from "antd";

const FileDownload = ({dispatch, id, failed, loading}) => {

    useEffect(() => {
        dispatch(FileDownloadActions.downloadFile(id))
    }, [])


    return <div className={'file-download-root'}>
        <h2>Datei herunterladen</h2>

        {(loading && !failed) && (
            <div style={{textAlign: 'center'}}>
                <Spin size={'large'}/>
                <div>
                    <small>Datei wird geladen...</small>
                </div>
            </div>
        )}

        {(!loading && !failed) && (
            <div style={{textAlign: 'center'}}>
                <Progress type="circle" percent={100}/>
                <div>
                    <small>Datei wurde heruntergeladen.</small>
                </div>
            </div>
        )}

        {failed && <div>
            <div style={{textAlign: 'center'}}>
                <Progress type="circle" percent={100} status={'exception'}/>
                <div>
                    <br/>
                    <p>Download fehlgeschlagen.</p>
                    <small>Bitte prüfen Sie die angegebene URL und stellen Sie sicher, dass Sie die notwendigen Rechte
                        zum Anzeigen dieser Datei haben.</small>
                </div>
            </div>
        </div>}
    </div>
}

export default withRouter(connect((state, props) => {
    console.log("Connected Folder", state, props);

    let {id} = props.match.params;

    return {
        id: id,
        failed: state.fileDownload.failed,
        loading: state.downloads.downloads && state.downloads.downloads.length > 0,
        // files: state.folder.files,
        // loading: state.folder.loading,
        // folder: state.folders.entities.folders[id],
        // // folder: state.folders.openFolder.current ? state.folders.entities.folders[state.folders.openFolder.current] : {},
        // folders: state.folders.entities.folders,
        // hierarchy: state.folders.openFolder.open
    }
})(FileDownload));
