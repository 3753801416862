import React from 'react'

export const DisplayFlex = ({direction = 'row', ...props}) => {
    return <div style={{
        display: 'flex',
        flexDirection: direction,
    }}>
        {props.children}
    </div>
}
