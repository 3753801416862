import React from 'react'
import {connect} from "react-redux";
import {Button, Input, Modal, Row} from "antd";
import * as ShareActions from '../ShareActions'
import CopyToClipboard from "../../../Components/Basic/CopyToClipboardButton";
import {DisplayFlex} from "../../../Components/Basic";


const ApiKeyResult = ({result: {apiKey}}) => {
    if (!apiKey)
        return null;
    return <div>
        <span>Api-Key</span>
        <DisplayFlex>
        <Input value={apiKey}/>
        <CopyToClipboard value={apiKey}/>
        </DisplayFlex>
    </div>
}


const TokenCodeAccessResult = ({result: {id, token, code}}) => {
    if (!token || !code)
        return null;

    let url = `${window.location.origin}/folder/${id}?token=${token}&code=${code}`

    return <div>
        <p>
            Mit folgender URL können Sie auf den Ordner zugreifen:
        </p>
        <DisplayFlex>
            <Input value={url}/> <CopyToClipboard value={url}/>
        </DisplayFlex>
    </div>
}


const ShareResultsModal = ({results, dispatch}) => {


    return results.map(result => {
        return <Modal title={"Zugang wurde erstellt"} key={result.id}
                      footer={[
                          <Button onClick={() => {
                              dispatch({type: 'MODAL_OK'})
                          }}
                                  type='primary'
                          >OK</Button>
                      ]}
                      onCancel={() => {
                          dispatch({type: 'MODAL_OK'})
                      }}
                      visible={true}>
            <div>
                <ApiKeyResult result={result}/>
                <TokenCodeAccessResult result={result}/>
            </div>
        </Modal>
    })
}

export default connect(state => ({
    results: state.share.results.map(id => state.share.entities[id]),
}))(ShareResultsModal)
