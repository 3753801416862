export const LOAD_FOLDER = 'LOAD_FOLDER';
export const loadFolder = () => ({type: LOAD_FOLDER, });

export const LOAD_FOLDER_DONE = 'LOAD_FOLDER_DONE';
export const loadFolderDone = (loginId, data) => ({type: LOAD_FOLDER_DONE, loginId, data});

export const OPEN_FOLDER = 'OPEN_FOLDER';
export const openFolder = (id) => ({type: OPEN_FOLDER, id});

export const CREATE_FOLDER = 'CREATE_FOLDER'
export const createFolder = (parentId, name, description, defaultRetentionTime) => ({type: CREATE_FOLDER, parentId, name, description, defaultRetentionTime})

export const OPEN_FOLDER_SET_ID = 'OPEN_FOLDER_SET_ID'
export const setOpenFolderId = (id) => ({type: OPEN_FOLDER_SET_ID, id})


export const LOAD_CHILDREN = 'LOAD_CHILDREN'
export const loadChildren = (id) => ({type: LOAD_CHILDREN, id})

export const LOAD_CHILDREN_DONE = 'LOAD_CHILDREN_DONE'
export const loadChildrenDone = (parentId, children) => ({type: LOAD_CHILDREN_DONE, parentId, children})