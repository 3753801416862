import {put, take, takeEvery, call, fork, takeLeading, cancel, join, race, select} from 'redux-saga/effects'
import axios from 'axios'
import * as FileDownloadActions from './FileDownloadActions'
import * as DownloadActions from '../FolderContent/DownloadActions'
import {getAxiosInstance} from "../Login/LoginSaga";

function* downloadFile({fileId}) {

    let logins = yield select(state => state.login);

    let loginInstances = logins.logins.map(login => logins.entities.logins[login]);

    for (let login of loginInstances) {
        // console.log("Login", login)
        let axiosInstance = yield getAxiosInstance(login.id);

        try {
            console.log("LoginInstances", loginInstances)
            const {data} = yield axiosInstance.get(`/file/${fileId}/`);
            console.log("APi response", data);
            yield put(DownloadActions.downloadFile(fileId, data.name, login.id));
            return;
        } catch (e) {
            console.log("Login Failed, try next")
        }
    }

    yield put(FileDownloadActions.downloadFailed())


}

export function* fileDownloadSaga() {
    yield takeEvery(FileDownloadActions.FILE_DOWNLOAD_DOWNLOAD, downloadFile)
}
