import * as LoginActions from './LoginActions'
import dotprop from 'dot-prop-immutable'
import {LoginTypes} from "./LoginTypes";

const initialState = {
    logins: [],
    entities: {
        logins: {},
    },
    folders: {},

    loginChecked: false,
    loginSuccess: false,

};

export default function LoginReducer(state = initialState, action) {

    switch (action.type) {

        case LoginActions.LOGOUT_ALL: {
            return {
                ...state,
                loginSuccess: false,

            }
        }
        case LoginActions.LOGIN: {
            let {login, loginType} = action;
            switch (loginType) {
                case LoginTypes.URL_CODE: {
                    let {id} = login;
                    return {
                        ...state,
                        logins: [...state.logins.filter(i => i === id), id],
                        entities: {
                            ...state.entities,
                            logins: dotprop.merge(state.entities.logins, id, login),
                        }
                    }
                }
            }
        }

        case LoginActions.RESTORE_LOGIN_DONE: {
            if(!action.logins)
                return state;

            let logins = action.logins.logins;
            let loginIds = action.logins.loginIds;

            return {
                ...state,
                logins: loginIds,
                entities: {
                    ...state.entities,
                    logins: logins,
                }
            }
        }

        case LoginActions.LOGIN_REQUIRED: {
            return {
                ...state,
                loginChecked: true,
                loginSuccess: false,
            }
        }
        case LoginActions.LOGIN_SUCCESS: {
            return {
                ...state,
                loginChecked: true,
                loginSuccess: true,
            }
        }
    }
    return state;
}
