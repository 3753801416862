export const FOLDER_CONTENT_LOAD = 'FOLDER_CONTENT_LOAD';
export const loadFolderContent = (id) => ({type: FOLDER_CONTENT_LOAD, id})

export const FOLDER_CONTENT_LOAD_DONE = 'FOLDER_CONTENT_LOAD_DONE';
export const loadFolderContentDone = (id, data) => ({type: FOLDER_CONTENT_LOAD_DONE, id, data})

export const FOLDER_CONTENT_LOAD_NOT_ALLOWED = 'FOLDER_CONTENT_LOAD_NOT_ALLOWED';
export const loadFolderContentNotAllowed = (id) => ({type: FOLDER_CONTENT_LOAD_NOT_ALLOWED, id});



