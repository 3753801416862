import React from 'react';
import './App.less'
import MainLayout from "./Screens/MainLayout";
import FolderContent from "./Screens/FolderContent";
import {Switch, Route} from 'react-router'
import LoginScreen from "./Screens/Login/LoginScreen";
import FileDownload from "./Screens/FileDownload/FileDownload";


// const store = createStore();

function App() {

    return (
        <LoginScreen>
            <MainLayout>
                <Switch>
                    <Route path={`/folder/:id`} component={FolderContent}/>
                    <Route path={`/file/:id/download`} exact={true} component={FileDownload}/>
                </Switch>
            </MainLayout>
        </LoginScreen>

    );
}


export default App;
