import React, {useState} from 'react'
import {Button, Input, Progress, Spin} from "antd";
import {connect} from "react-redux";
import {useLocation, useParams} from "react-router";
import * as LoginActions from './LoginActions'
import styled from 'styled-components'
import './login.less'

const ScreenOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(255,255,255,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginInProgress = () => (
    <div className={'login-progress'}>
        <Spin size={'large'}/>
        <div>
            <small>Anmeldung wird geprüft...</small>
        </div>
    </div>
);

const LoginForm = () => {
    let [username, setUsername] = useState('');
    let [password, setPassword] = useState('');
    return <div className={'login-form'}>
        <h2>Anmeldung</h2>
        <Input value={username}
               placeholder={'Benutzername'}
               onChange={e => setUsername(e.target.value)}/>
        <Input value={password}
               placeholder={'Passwort'}
               onChange={e => setPassword(e.target.value)}/>
        <Button type={'primary'} block>Anmelden</Button>
    </div>
}

const OAuthLoginButton = ({onClick}) => {
    return <div>
        <Button block
                onClick={onClick}
                style={{backgroundColor: "#bada55", borderColor: '#bada55'}}
                type='default'>mit envago.login anmelden</Button>
    </div>
}


const LoginScreen = ({loading, loginSuccess, dispatch, ...props}) => {


    // console.log(urlParams.forEach((v, k) => {
    //     console.log("Params", k, v);
    // }))
    // dispatch(LoginActions.noLoginExtracted());


    if (loginSuccess)
        return props.children;


    return <>
        <ScreenOverlay className={'login-overlay'}>
            <div>
                {loading && <LoginInProgress/>}
                {(!loading && !loginSuccess) && <LoginForm/>}

                {/*<LoginForm />*/}

                {/*<OAuthLoginButton onClick={()=>{*/}
                {/*    dispatch(LoginActions.loginOauth());*/}
                {/*}}/>*/}
            </div>
        </ScreenOverlay>
        {/*{props.children}*/}
    </>
};

export default connect(state => ({
    loading: !state.login.loginChecked,
    loginSuccess: state.login.loginSuccess,
}))(LoginScreen);
