import {applyMiddleware, createStore} from 'redux'
import createSagaMiddleware, {END} from 'redux-saga'

import {logger} from "redux-logger";
import createReducers from './Reducers'
import {createBrowserHistory} from "history";
import { routerMiddleware } from 'connected-react-router'




const history = createBrowserHistory();
const rootReducer = createReducers(history);

export default (preloadedState) => {

    console.log("PreloadedState", preloadedState)
    // const reducers = createReducers(history);

    const sagaMiddleware = createSagaMiddleware();


    const store = createStore(
        rootReducer,
        preloadedState,
        applyMiddleware(
            sagaMiddleware,
            routerMiddleware(history),
            logger
        ),
    );




    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./Reducers', () => {
            const createnextRootReducer = require('./Reducers');

            // console.log("nexrRootReducer", nextRootReducer)
            store.replaceReducer(createnextRootReducer(history))
        })
    }

    store.runSaga = sagaMiddleware.run
    store.close = () => store.dispatch(END)

    return [store, history];
}
