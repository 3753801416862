import * as FoldersActions from "../../Folders/FoldersActions";
import {Button, Checkbox, Input, Popover, Select, Form, Row, Upload} from "antd";
import React, {useState, useCallback, useEffect} from "react";
import ShareResultsModal from "./ShareResultsModal";


class UploadFileButton extends React.Component {

    state = {
        visible: false,
    }

    componentDidMount() {
        this.props.form.validateFields();
    }

    render() {
        let {folder, onUpload} = this.props;
        let {visible} = this.state;
        let {permissions} = folder;
        const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched} = this.props.form;

        if (folder.permissions && folder.permissions.write === false)
            return null;
        console.log("Share", folder);

        return <>
            <Upload
                fileList={[]}
                beforeUpload={(file) => {
                    console.log("File");
                    onUpload(file);
                    return false;
                }}>
                <Button size={'small'} icon={'upload'}/>
            </Upload>
        </>
    }
};
export default Form.create({})(UploadFileButton);
