import React, {useCallback} from 'react'
import {useDispatch} from "react-redux";
import {useDropzone} from "react-dropzone";
import './UploadDropzone.less'

const UploadDropzone = ({children, folder, onUpload}) => {


    // console.log("Folder", folder)
    const onDrop = useCallback(acceptedFile => {
        // console.log("Accepted", acceptedFile)

        for (let file of acceptedFile) {
            // console.log("Files", file)
            onUpload(file);
        }

        // onUpload(acceptedFile);
    }, [folder])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop
    })

    if (folder.permissions && folder.permissions.write === false)
        return children;

    // console.log("Root Props", getRootProps)

    return <div
        {...getRootProps({
            onClick: (e) => {
                e.stopPropagation();
            },
        })}
        className={"upload-dropzone-root"}
    >
        <input {...getInputProps()}/>
        {isDragActive && (
            <div className={'upload-dropzone-popover'}>
                <div className={'upload-dropzone-popover-content'}>
                    <h2>Dateien hochladen</h2>
                    <p>
                        Loslassen, um Dateien in den Ordner <code>{folder.name}</code> hochzuladen.
                    </p>
                </div>
            </div>
        )}
        <div
            className={['upload-dropzone-content', isDragActive && `upload-dropzone-content-blur`].filter(a => a).join(' ')}>
            {children}
        </div>
    </div>
}

export default UploadDropzone
