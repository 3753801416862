import {put, take, takeEvery, call, takeLeading, takeLatest} from 'redux-saga/effects'
import * as ShareActions from './ShareActions'
import axios from 'axios'
import {getAxiosInstance} from "../Login/LoginSaga";
import {getFolderLoginId} from "../Folders/FoldersSaga";
import {baseUrl} from "../../Const";


function* shareFolder({id, request}){
    console.log("Send request", id, request);

    let loginId = yield call(getFolderLoginId, id);
    console.log("Login for folder", loginId, id)
    let axiosInstance = yield call(getAxiosInstance, loginId);

    let shareResult = yield axiosInstance.post(`${baseUrl}/api/bucket/${id}/access`, request, {
        // headers: {
        //     'X-Api-Key' : "abcdefg"
        // }
    });
    console.log("ShareResult", shareResult);
    yield put(ShareActions.shareFolderDone(id, shareResult.data));

    yield take('MODAL_OK');

    yield put(ShareActions.shareFolderRemoveResult(id))

}

export default function* shareSaga(){
    yield takeLatest(ShareActions.SHARE_FOLDER, shareFolder);

}
