import React, {useEffect} from 'react'
import {Icon, Menu, Tree} from "antd";
import {connect} from "react-redux";
import * as FolderActions from "./FoldersActions";
import './FoldersMenu.less'
import {push} from 'connected-react-router'

let {SubMenu} = Menu;
const {TreeNode} = Tree;


// let Folder = connect((state, props) => ({
//     folder: state.folders.entities.folders[props.id],
//     open: state.folders.openFolder.open.indexOf(props.id) > -1,
//     currentOpen: state.folders.openFolder.current === props.id,
// }))(({folder, open = false, currentOpen = false, dispatch}) => {
//     console.log("Folder", folder, open, currentOpen)
//     if (!folder)
//         return <div>no content</div>
//     return <div className={'folder-wrapper'}>
//         <div className={['folder', open && 'folder-child-open', currentOpen && 'folder-open'].filter(a => a).join(' ')}
//              onClick={() => {
//                  dispatch(FolderActions.openFolder(folder.id));
//              }}>
//         <span>
//             <Icon className={'folder-icon'} type={currentOpen ? 'folder-open' : 'folder'}/>
//             <span className={'folder-name'}>{folder.name}</span>
//         </span>
//         </div>
//         <div className={'subfolders'}>
//             {open && folder.children && folder.children.map(sub => <Folder key={sub} id={sub}/>)}
//         </div>
//     </div>
// });


function isChildOpen(openId, folder, folders) {
    //console.log("Check Open", openId, folder, folders);
    if (folder.id === openId)
        return true;
    if (!folder.children)
        return false;
    let childOpen = folder.children.map(cid => folders[cid]).reduce((res, child) => res || isChildOpen(openId, child, folders), false)
    if (childOpen === true)
        return true;
    return false;
}

const Folder = connect((state, props) => ({
    folder: state.folders.entities.folders[props.id],
    folders: state.folders.entities.folders,
    openId: state.folders.open,
}))(({dispatch, folder, openId, folders}) => {

    useEffect(() => {
        // console.log("Folder, load sub", folder.id)
    }, [folder.id]);


    console.log("FolderXXX", folder, folder.hasChildren)

    let isOpen = isChildOpen(openId, folder, folders);
    // console.log("IS OPEN? ", isOpen, openId, folder);

    let hasChildren = folder.hasChildren || folder.children && folder.children.length > 0;

    console.log("Folder", folder);
    return <div className={'folder-wrapper'}>
        <div className={['folder', hasChildren && 'folder-has-children', isOpen && 'folder-child-open', openId === folder.id && 'folder-open'].filter(Boolean).join(" ")}
             onClick={() => {
                 dispatch(FolderActions.openFolder(folder.id))
             }}>
            <Icon style={{width: 15, height: 15}} className={'folder-icon'} type={openId === folder.id ? 'folder-open' : isOpen ? 'folder' : hasChildren ? 'plus-square' : 'folder'}/>
            <span className={'folder-name'}>{folder.name}</span>
        </div>
        <div className={'subfolders'}>
            {isOpen && folder.children && folder.children.map(sub => <Folder key={sub} id={sub}/>)}
        </div>
    </div>
})

const FoldersMenu = ({dispatch, folders}) => {
    return (

        <div className={'folder-tree'}>
            {folders.map(folder => <Folder key={folder.id} id={folder.id}/>)}
        </div>
    )
}

export default connect(state => ({
    folders: state.folders.result.map(folderKey => (
            state.folders.entities.folders[folderKey]
        )
    ),
}))(FoldersMenu);
