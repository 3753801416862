import * as FolderActions from './FolderActions'
import * as FolderSActions from '../Folders/FoldersActions'
import dotprop from 'dot-prop-immutable'
import {normalize, schema} from 'normalizr'

const initialState = {
    loading: false,
    loaded: false,
    files: [],
};


export default function FolderReducer(state = initialState, action) {
    switch (action.type) {
        // case FolderSActions.OPEN_FOLDER: {
        //     return initialState;
        // }
        case FolderActions.FOLDER_CONTENT_LOAD_NOT_ALLOWED: {
            return {
                ...state,
                loading: false,
                loaded: true,
                files: [],
            }
        }
        case FolderActions.FOLDER_CONTENT_LOAD: {
            return {
                ...state,
                loading: true,
                loaded: false,
                files: [],
            }
        }
        case FolderActions.FOLDER_CONTENT_LOAD_DONE: {
            let {data} = action;
            return {
                ...state,
                loading: false,
                loaded: true,
                files: [...data],
            }
        }

    }
    return state;
}


