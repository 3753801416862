import React, {useEffect, useMemo} from 'react'
import {Breadcrumb, Button, Icon, Row, Table, Tooltip} from 'antd'
import moment from 'moment'
import './style.less'
import {humanFileSize, randomId} from "../../Helper/FileHelper";
import FileIcon from "../../Components/File/FileIcon";
import {connect} from "react-redux";
import * as DownloadActions from './DownloadActions'
import * as FolderActions from './FolderActions'
import * as FoldersActions from '../Folders/FoldersActions'
import Toolbar from "../Basic/Toolbar";
import CreateSubfolderButton from "./Components/CreateSubfolderButton";
import DurationInput from "../../Components/Duration/DurationInput";
import DownloadNotifications from "./DownloadNotifications";
import {useParams, withRouter} from "react-router";
import {push} from 'connected-react-router'
import CreateAccessButton from "./Components/CreateAccessButton";
import {DisplayFlex} from "../../Components/Basic";
import * as ShareActions from "./ShareActions";
import UploadFileButton from "./Components/UploadFileButton";
import UploadDropzone from "./UploadDropzone";

const FolderBreadcumb = ({folders, id, onOpenFolder}) => {

    let hierarchy = [];

    let currentFolder = folders[id];
    hierarchy.push(currentFolder);
    while (currentFolder.parent) {
        currentFolder = folders[currentFolder.parent];
        hierarchy.push(currentFolder);
    }

    console.log("Hierarchy", hierarchy)

    return <Breadcrumb>
        {hierarchy.reverse().map(folder => {
            return <Breadcrumb.Item key={folder.id}
                                    href={`/folder/${folder.id}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onOpenFolder(folder.id);
                                    }}>
                {folder.name}
            </Breadcrumb.Item>
        })}
    </Breadcrumb>
}

const FolderContent = ({loading, files, folder, folders, hierarchy, dispatch, id}) => {

    useEffect(() => {
        console.log("Use effect", id);
        // dispatch(FolderActions.loadFolderContent(id));
        dispatch(FoldersActions.openFolder(id))
    }, [id]);

    if (!folder)
        return null;


    const columns = [
        {
            title: "Name",
            key: 'name',
            dataIndex: 'name',
            render: (data, item) => {
                if (item.type === 'file') {
                    return <span>
                        <FileIcon type={item.mediaType}/>
                        <span style={{marginLeft: 4,}}>{data}</span>
                    </span>
                } else {
                    return <span>
                        <Icon type={'folder'}/>
                        <span style={{marginLeft: 4,}}>{data}</span>
                    </span>
                }
            }
        },
        {
            title: "Datum",
            key: 'createdAt',
            dataIndex: 'createdAt',
            className: 'small grey',
            width: 120,
            render: (data) => data && <Tooltip
                title={moment(data).format("DD.MM.YYYY HH:mm:ss")}>{moment(data).format("DD.MM.YYYY")}</Tooltip>
        },
        {
            title: "Größe",
            key: 'size',
            dataIndex: 'size',
            width: 120,
            className: 'small grey',
            render: data => data && humanFileSize(data)
        },
    ];

    // console.log("FolderContent", folder)

    let subfolders = (folder.children || []).map(f => ({type: 'folder', ...folders[f]})).map(sub => ({
        ...sub,
        children: null,
    }));

    let data = [
        ...subfolders,
        ...files.map(f => ({...f, type: 'file'}))
    ];
    // console.log("Data", data);

    return (
        <div className={'folder-content-root'}>
            <UploadDropzone folder={folder} onUpload={(file) => {
                console.log("Upload", file, "folder", folder);
                dispatch(DownloadActions.uploadFile(file, file.id || file.uid || randomId(), folder.id, folder.loginId));
            }}>
                <DisplayFlex>

                    <FolderBreadcumb onOpenFolder={(id) => {
                        // dispatch(push(`${id}`))
                        dispatch(FoldersActions.openFolder(id))
                    }} folders={folders} id={id}/>

                    <Toolbar>
                        <UploadFileButton folder={folder} onUpload={(file) => {
                            console.log("Upload", data, "folder", folder);
                            dispatch(DownloadActions.uploadFile(file, file.uid, folder.id, folder.loginId));
                        }}/>
                        <CreateAccessButton folder={folder} onSave={(result) => {
                            console.log("Got Result", result);
                            dispatch(ShareActions.shareFolder(id, result));
                        }}/>
                        <CreateSubfolderButton dispatch={dispatch} folder={folder}/>
                    </Toolbar>
                </DisplayFlex>
                <DownloadNotifications/>

                <Table
                    onRow={(record, index) => ({
                        onClick: e => {
                            if (record.type === 'folder') {
                                dispatch(FoldersActions.openFolder(record.id))
                                //dispatch(push(`${record.id}`))
                            } else {
                                dispatch(DownloadActions.downloadFile(record.id, record.name, folder.loginId));
                            }
                        },
                    })}
                    rowKey={record => record.id}
                    rowClassName={(record, index) => record.type === 'folder' ? 'folder-row' : 'file-row'}
                    loading={loading}
                    className={'folder-content-table no-border'}
                    size={'small'}
                    bordered={false}
                    columns={columns}
                    dataSource={data}
                />
            </UploadDropzone>
        </div>
    );
}


export default withRouter(connect((state, props) => {
    console.log("Connected Folder", state, props);

    let {id} = props.match.params;

    return {
        id: id,
        files: state.folder.files,
        loading: state.folder.loading,
        folder: state.folders.entities.folders[id],
        // folder: state.folders.openFolder.current ? state.folders.entities.folders[state.folders.openFolder.current] : {},
        folders: state.folders.entities.folders,
        hierarchy: state.folders.openFolder.open
    }
})(FolderContent));
