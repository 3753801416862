import React, {useState} from 'react'
import {Button, Popover, Tooltip} from "antd";
import CopyClipboard from 'react-copy-to-clipboard'

const CopyToClipboard = ({value}) => {

    let [visible, setVisible] = useState(false);

    const onClick = () => {
        setVisible(true);
        setTimeout(() => {
            setVisible(false);
        }, 1000)
    }

    return <Popover visible={visible}
                    onVisibleChange={v => {
                        console.log("onVisibleChanged", v)
                        if (v === true) {

                        }
                    }}
                    content={<span>Wurde in die Zwischenablage kopiert</span>}
                    trigger={'click'}>

        <CopyClipboard text={value} onCopy={() => {
            onClick();
        }}>
            <Button icon={'copy'} onClick={() => {

            }}/>
        </CopyClipboard>

    </Popover>
}

export default CopyToClipboard;
