import React from 'react'


const Head = () => (
    <>
        <div className={'logo'}>
            <img src={require('../../Assets/small_logo_white_transparent.png')}/>
        </div>
        <h1>drive</h1>
    </>
)

export default Head;
