import * as FoldersActions from "../../Folders/FoldersActions";
import {Button, Checkbox, Input, Popover, Select, Form, Row} from "antd";
import React, {useState, useCallback, useEffect} from "react";
import DurationInput from "../../../Components/Duration/DurationInput";
import ShareResultsModal from "./ShareResultsModal";


class CreateAccessButton extends React.Component {

    state = {
        visible: false,
    }

    componentDidMount() {
        this.props.form.validateFields();

    }

    render() {
        let {folder, onSave} = this.props;
        let {visible} = this.state;
        let {permissions} = folder;
        const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched} = this.props.form;

        if (folder.permissions && folder.permissions.writeAcl === false)
            return null;
        console.log("Share", folder);

        return <>
            <ShareResultsModal/>
            <Popover
                trigger={'click'}
                overlayStyle={{
                    width: 500,
                }}
                title={'Freigeben'}
                placement="bottomRight"
                visible={visible}
                onVisibleChange={(v) => this.setState({visible: v})}
                content={(
                    <Form layout="vertical" onSubmit={(e) => {
                        e.preventDefault();

                    }}>

                        <Form.Item label={<small>Freigabe-Typ</small>}>
                            {getFieldDecorator('accessType', {
                                initialValue: 'USER_ACCESS',
                            })(
                                <Select>
                                    <Select.Option value={'API_KEY_ACCESS'}>Api-Schlüssel</Select.Option>
                                    <Select.Option value={'URL_CODE_ACCESS'}>URL Zugriff</Select.Option>
                                    <Select.Option value={'USER_ACCESS'}>Benutzer</Select.Option>
                                </Select>
                            )}
                        </Form.Item>

                        <h4>Berechtigungen</h4>
                        <Form.Item label={'Dateien & Unterordner'}>
                            {getFieldDecorator('permissions.read', {
                                initialValue: false,
                            })(
                                <Checkbox defaultChecked={false} disabled={permissions.read === false}>Lesen</Checkbox>
                            )}
                            {getFieldDecorator('permissions.write', {
                                initialValue: false,
                            })(
                                <Checkbox defaultChecked={false} disabled={permissions.write === false}>Erstellen</Checkbox>
                            )}
                            {getFieldDecorator('permissions.write', {
                                initialValue: false,
                            })(
                                <Checkbox defaultChecked={false} disabled={permissions.delete === false}>Löschen</Checkbox>
                            )}
                        </Form.Item>
                        <Form.Item label={'Freigaben'}>
                            {getFieldDecorator('permissions.readAcl', {
                                initialValue: false,
                            })(
                                <Checkbox defaultChecked={false} disabled={permissions.readAcl === false}>Lesen</Checkbox>
                            )}
                            {getFieldDecorator('permissions.writeAcl', {
                                initialValue: false,
                            })(
                                <Checkbox defaultChecked={false} disabled={permissions.writeAcl === false}>Erstellen</Checkbox>
                            )}
                            {getFieldDecorator('permissions.deleteAcl', {
                                initialValue: false,
                            })(
                                <Checkbox defaultChecked={false} disabled={permissions.deleteAcl === false}>Löschen</Checkbox>
                            )}

                        </Form.Item>


                        < Button block={true} onClick={() => {
                            this.props.form.validateFields((err, values) => {
                                console.log("Validate", err, values);
                                if (err) {
                                    return;
                                }
                                onSave(values);
                                this.setState({visible: false})
                            });
                        }}>
                            Speichern
                        </Button>

                    </Form>
                )}
            >

                <Button size={'small'} icon={'share-alt'}/>
            </Popover>
        </>
    }
};
export default Form.create({})(CreateAccessButton);
