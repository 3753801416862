import {call, put, takeLatest, select, take, fork, takeEvery} from 'redux-saga/effects'
import localstorage from 'local-storage';
import * as LoginActions from './LoginActions'
import {loadFolder} from "../Folders/FoldersSaga";
import {useLocation} from "react-router";
import {LoginTypes} from "./LoginTypes";
import axios from 'axios'
import * as FoldersActions from '../Folders/FoldersActions'
import {normalize} from "normalizr";
import {folder} from "../Folders/FoldersReducer";


export function* getAxiosInstance(loginId) {
    console.log("get instance for", loginId);


    let login = yield select(state => state.login.entities.logins[loginId])


    console.log("Found Login For FOlder", login)
    if (login) {
        let instance = axios.create();
        instance.interceptors.request.use(config => {
            config.params = {
                ...config.params,
                ...login.requestConfig.params
            };
            return config;
        })

        return instance;
    }
    return axios.create();
}


function* restoreLogin() {
    // localstorage.backend(sessionStorage);
    let logins = localstorage('eDriveLogins');

    yield put(LoginActions.restoreLogin(logins))
}

function* storeLogin({login}) {
    // localstorage.backend(sessionStorage);
    let envagoLogins = localstorage('eDriveLogins') || {loginIds: [], logins: {}};

    if (!envagoLogins.loginIds.includes(login.id))
        envagoLogins.loginIds.push(login.id);
    envagoLogins.logins[login.id] = login;
    localstorage('eDriveLogins', envagoLogins);
}

function* clearLoginStorage() {
    localstorage.remove(`eDriveLogins`);
}

function* checkLogin() {
    console.log("Check Login")
    let location = yield select(state => state.router.location)
    let folderId = (location.pathname.match(/\/folder\/(\w*)/) || []);

    let loginId = yield call(extractLogins);

    let logins = yield take(LoginActions.RESTORE_LOGIN_DONE);
    if (logins.logins) {
        let {loginIds} = logins.logins;

        console.log("Restored Logins", loginIds)

        for (let loginId of loginIds) {
            console.log("Login", loginId)
            try {
                yield call(loadFolder, loginId);
            } catch (e) {
                console.log("Loginid invalid", loginId)
            }
        }
        let folders = yield select(state => state.folders.entities.folders);
        let requestedFolder = folders[folderId];

        console.log("loginIds?", loginIds, requestedFolder)

        if (requestedFolder && requestedFolder.loginId) {
            console.log('loginIds', loginIds)
            if (loginIds.includes(requestedFolder.loginId)) {
                yield put(LoginActions.loginSuccess());
                return;
            }
        } else {
            console.log("perhabs also successful...")
            if (loginIds.length > 0) {
                yield put(LoginActions.loginSuccess());
                return;
            }
        }
    }

    try {

        if (loginId) {
            yield call(loadFolder, loginId);
            yield put(LoginActions.loginSuccess());
            return;
        }
    } catch (e) {
        yield put(LoginActions.loginRequired());
        return;
    }
    yield put(LoginActions.loginRequired());


    // }
}

function* login(loginType, login) {
    console.log("LOGIN!", login);
    yield call(storeLogin, {login});
    yield put(LoginActions.login(loginType, login))
}


function* extractLogins() {
    let location = yield select(state => state.router.location)
    let {search} = location;
    console.log("location", location)

    let urlParams = new URLSearchParams(search);
    let code = urlParams.get('code');
    let token = urlParams.get('token');

    if (code && token) {

        // let folderId = location.pathname.match(/\/folder\/(\w*)/)[1];

        yield call(login, LoginTypes.URL_CODE, {
            id: token,
            type: LoginTypes.URL_CODE,
            requestConfig: {
                params: {
                    token,
                    code,
                }
            }
        });
        return token;
    }
    return false;

}

function* logout() {
    yield call(clearLoginStorage);
    yield call(checkLogin);
}

function* loginWithOauth() {

}

export default function* loginSaga() {

    // yield takeLatest(LoginActions.NO_LOGIN_EXTRACTED, checkLogin);
    // yield takeLatest(LoginActions.LOGIN_OAUTH, loginWithOauth);

    yield takeEvery(LoginActions.LOGOUT_ALL, logout);

    yield fork(checkLogin);

    yield call(restoreLogin);


}

