import {put, take, takeEvery, call, takeLeading, takeLatest, select} from 'redux-saga/effects'
import axios from 'axios'
import * as FoldersActions from '../Folders/FoldersActions'
import * as FolderActions from './FolderActions'
import { saveAs } from 'file-saver';
import {push} from 'connected-react-router'
import {getAxiosInstance} from "../Login/LoginSaga";
import {getFolderLoginId} from "../Folders/FoldersSaga";
import {baseUrl} from "../../Const";

function* openFolder({id}) {
    console.log("openFolder()", id)
    yield put(push(`/folder/${id}`))
    yield put(FolderActions.loadFolderContent(id))
    // yield call(loadFolderContent, id)
}


function* loadFolderContent({id}){
    let loginId;
    try {
        loginId = yield call(getFolderLoginId, id);
    } catch(e){
        console.log("Cant load content, no loginid");
        return;
    }

    try {

        let axiosInstance = yield getAxiosInstance(loginId);

        let bucketResult = yield axiosInstance.get(`${baseUrl}/api/bucket/${id}/files`, {
            params: {
                size: 1000,
                sort: 'createdAt,DESC'
            }
            // headers: {
            //     'X-Api-Key' : "abcdefg"
            // }
        });
        yield put(FolderActions.loadFolderContentDone(id, bucketResult.data.content));
    } catch(e){
        yield put(FolderActions.loadFolderContentNotAllowed(id));
    }
}



export function* folderSaga() {

    yield takeLatest(FoldersActions.OPEN_FOLDER, openFolder)
    yield takeLatest(FolderActions.FOLDER_CONTENT_LOAD, loadFolderContent)
    // yield takeLeading(FoldersActions.FOL, openFolder);


}
