import * as ShareActions from './ShareActions'
import dotprop from 'dot-prop-immutable'
import {normalize, schema} from 'normalizr'

const initialState = {
    loading: false,
    loaded: false,
    results: [],
    entities: {},
};


export default function ShareReducer(state = initialState, action) {
    switch (action.type) {
        case(ShareActions.SHARE_FOLDER_DONE): {
            let {id, result} = action;
            return {
                ...state,
                results: dotprop.set(state.results, '$end', id),
                entities: dotprop.merge(state.entities, id, {id, ...result}),
            }
        }
        case ShareActions.SHARE_FOLDER_REMOVE_RESULT: {
            let {id} = action;
            return {
                ...state,
                results: state.results.filter(i => i !== id),
                entities: dotprop.delete(state.entities, id),
            }
        }
    }
    return state;
}


