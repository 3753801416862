import React from 'react'
import {connect} from "react-redux";
import {Col, Icon, Progress, Row} from "antd";
import './DownloadNotifications.less'
import {humanFileSize} from "../../Helper/FileHelper";
import * as DownloadActions from './DownloadActions'

const DownloadProgress = ({name, progress = 0, total = -1, onCancel, done}) => {
    return <div className={'download-progress'}>
        <Row>
            <Col span={6}>
                {name}
            </Col>
            <Col span={13}>
                <Progress
                    status={'active'}
                    percent={total === -1 ? 0 : (progress * 100. / total).toFixed(1)}/>
            </Col>
            <Col span={4} style={{textAlign: 'right'}}>
                {total > 0 && <small>{humanFileSize(progress)} / {humanFileSize(total)}</small>}
            </Col>
            <Col span={1} style={{textAlign: 'right'}}>
                {!done && <Icon type={'close-circle'} onClick={() => {
                    onCancel();
                }}/>}
            </Col>
        </Row>
    </div>
}


const DownloadNotifications = ({downloads, dispatch}) => {
    if(!downloads || downloads.length === 0)
        return null;
    return <div className={'download-notifications'}>
        {downloads.map(d => {
            return <DownloadProgress {...d} key={d.id} onCancel={() => {
                dispatch(DownloadActions.downloadCancel(d.id));
            }}/>
        })}
    </div>
}


export default connect(state => ({
    downloads: state.downloads.downloads.map(d => state.downloads.entities[d])
}))(DownloadNotifications);
