import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import FoldersReducer from "../Screens/Folders/FoldersReducer";
import FolderReducer from "../Screens/FolderContent/FolderReducer";
import DownloadReducer from "../Screens/FolderContent/DownloadReducer";
import ShareReducer from "../Screens/FolderContent/ShareReducer";
import LoginReducer from "../Screens/Login/LoginReducer";
import FileDownloadReducer from "../Screens/FileDownload/DownloadReducer";


export default function createReducers(history) {
    console.log("Create Reducer2", history)
    const reducers = combineReducers({
        router: connectRouter(history),
        folders: FoldersReducer,
        folder: FolderReducer,
        downloads: DownloadReducer,
        share: ShareReducer,
        login: LoginReducer,
        fileDownload: FileDownloadReducer,
    })



    return reducers;
};
// console.log('reducersssss')

// const reducers = combineReducers({
//     router: connectRouter(history),
//     folders: FoldersReducer,
//     folder: FolderReducer,
// })
//
// export default reducers;
