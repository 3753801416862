import {put, take, takeEvery, call, fork, takeLeading, cancel, join, race} from 'redux-saga/effects'
import {eventChannel, END} from 'redux-saga'
import axios from "axios";
import {saveAs} from "file-saver";
import * as DownloadActions from "./DownloadActions";
import {getAxiosInstance} from "../Login/LoginSaga";
import {baseUrl} from "../../Const";
import {loadFolderContent} from "./FolderActions";

function* downloadFile({id, name, loginId}) {

    yield put(DownloadActions.downloadFileStart(id));

    let axiosInstance = yield getAxiosInstance(loginId);

    const [fileDownloadChannel, promise, cancelRequest] = downloadEmitter(id, axiosInstance);
    let progressWatcher = yield fork(watchProgress, fileDownloadChannel);


    let {result, canceled} = yield race({
        result: promise,
        canceled: take((action) => action.type === DownloadActions.DOWNLOAD_CANCEL && action.id === id),
    });

    console.log("Race resukt", result, canceled)

    if (result) {
        console.log("RESULT", result);

        yield cancel(progressWatcher);

        let file = result.data;
        // console.log("Result", result, file);
        console.log(result.headers['content-disposition'])

        yield saveAs(file, name);
        yield put(DownloadActions.downloadFileDone(id));
    } else {
        console.log("Canceled");
        try {
            cancelRequest();
        } catch (e) {
            console.log("catched error");
        }
        yield cancel(progressWatcher);
    }


}

function* watchProgress(chan) {
    while (true) {
        const {id, loaded, total} = yield take(chan);
        yield put(DownloadActions.downloadProgress(id, loaded, total));
    }
}

function downloadEmitter(id, axiosInstance) {
    let emit;

    let channel = eventChannel(emitter => {
        emit = emitter;
        return () => {
            console.log("unsubscribe not implemented");
        }
    });

    const CancelToken = axios.CancelToken;
    let cancel;


    let promise = axiosInstance.get(`${baseUrl}/file/${id}/download`, {
        responseType: 'blob',
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        }),
        // headers: {
        //     'X-Api-Key': "abcdefg"
        // },
        onDownloadProgress: (p) => {
            console.log("Progress", p);
            emit({loaded: p.loaded, total: p.total, id});
        }
    });
    return [channel, promise, cancel];
}

function uploadEmitter(file, folderId, axiosInstance) {
    let emit;

    let channel = eventChannel(emitter => {
        emit = emitter;
        return () => {
            console.log("unsubscribe not implemented");
        }
    });

    const CancelToken = axios.CancelToken;
    let cancel;

    let form = new FormData();
    form.append('file', file);

    let promise = axiosInstance.post(`${baseUrl}/bucket/${folderId}/file`, form, {
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        onUploadProgress: (p) => {
            console.log("Progress", p);
            emit({loaded: p.loaded, total: p.total, id: file.uid});
        }
    });


    return [channel, promise, cancel];
}

function* watchUploadProgress(chan) {
    while (true) {
        const {id, loaded, total} = yield take(chan);
        yield put(DownloadActions.uploadProgress(id, loaded, total));
    }
}

function takeLeadingPerKey(patternOrChannel, worker, keySelector, ...args) {
    return fork(function* () {
        const tasks = {};

        yield takeEvery(patternOrChannel, function* (action) {
            const key = yield call(keySelector, action);

            if (!(tasks[key] && tasks[key].isRunning())) {
                tasks[key] = yield fork(worker, ...args, action);

                yield join(tasks[key]);

                if (tasks[key] && !tasks[key].isRunning()) {
                    delete tasks[key];
                }
            }
        });
    });
}


function* uploadFile({file, folderId, loginId, id}) {
    console.log("uplaod", file, folderId, loginId);

    if (!file.uid)
        file.uid = id;
    let axiosInstance = yield getAxiosInstance(loginId);


    const [fileUploadChannel, promise, cancelRequest] = uploadEmitter(file, folderId, axiosInstance);
    let progressWatcher = yield fork(watchUploadProgress, fileUploadChannel);


    let {result, canceled} = yield race({
        result: promise,
        canceled: take((action) => action.type === DownloadActions.DOWNLOAD_CANCEL && action.id === file.uid),
    });

    if (result) {
        yield put(DownloadActions.uploadFileDone(file.uid));
        yield put(loadFolderContent(folderId));
    } else {
        try {
            cancelRequest();
        } catch (e) {
            console.log("catched error");
        }
        yield cancel(progressWatcher);
    }


}

export default function* downloadSaga() {
    yield takeLeadingPerKey(DownloadActions.DOWNLOAD_FILE, downloadFile, action => action.id);
    yield takeEvery(DownloadActions.UPLOAD_FILE, uploadFile);
}
