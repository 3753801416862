import React, {useState, useMemo, useCallback, useEffect} from 'react'
import {Input, Select} from "antd";
import './DurationInput.less'
import moment from 'moment'

const DurationInput = ({onChange, defaultValue, disabled}) => {

    // let [duration, setDuration] = useState(moment.duration('P32DDT5H30M'))

    // useEffect(() => {
    //     onChange(defaultValue)
    // });

    let [durationValue, setDurationValue] = useState(30);
    let [durationUnit, setDurationUnit] = useState('days')

    // const duration = useMemo(() => moment.duration(`P${month}M${days}DT${hours}H${minutes}M`), [month, days, hours, minutes]);


    let x = useEffect(() => {

        console.log("durationValue", durationValue)
        if (isNaN(durationValue))
            return 'PT0H';
        let newDuration = moment.duration(parseInt(durationValue), durationUnit)


        let durationString = 'P';
        if (newDuration.asDays() > 0)
            durationString += Math.floor(newDuration.asDays()) + 'D';
        if (newDuration.hours() > 0 || newDuration.minutes() > 0 || newDuration.seconds() > 0) {
            durationString += 'T';
            if (newDuration.hours() > 0)
                durationString += newDuration.hours() + 'H';
            if (newDuration.minutes() > 0)
                durationString += newDuration.minutes() + 'M';
            if (newDuration.seconds() > 0)
                durationString += newDuration.seconds() + 'S';
        }


        console.log("newDurationString", durationString)
        onChange(durationString);
    }, [durationValue, durationUnit])


    return <div className={'duration-input'}>
        <Input disabled={disabled} value={durationValue} onChange={e => {
            if (e.target.value.length === 0) {
                setDurationValue(0);
            } else {
                setDurationValue(parseInt(e.target.value))
            }
        }}
        />
        <Select disabled={disabled} value={durationUnit} style={{width: 120}} onChange={(v) => {
            setDurationUnit(v);
        }}>
            <Select.Option value="years">Jahre</Select.Option>
            <Select.Option value="months">Monate</Select.Option>
            <Select.Option value="days">Tage</Select.Option>
            <Select.Option value="hours">Stunden</Select.Option>
            <Select.Option value="minutes">Minuten</Select.Option>
        </Select>

        {/*<div className={'duration-block'}>*/}
        {/*    <Input size={'small'}*/}
        {/*           onChange={(e) => setMonth(e.target.value)}*/}
        {/*           value={duration.asMonths()}/>*/}
        {/*    <small>Monate</small>*/}
        {/*</div>*/}
        {/*<div className={'duration-block'}>*/}
        {/*    <Input size={'small'}*/}
        {/*           onChange={(e) => setDays(e.target.value)}*/}
        {/*           value={duration.days()}/>*/}
        {/*    <small>Tage</small>*/}
        {/*</div>*/}
        {/*<div className={'duration-block'}>*/}
        {/*    <Input size={'small'} value={duration.hours()}/>*/}
        {/*    <small>Stunden</small>*/}
        {/*</div>*/}
        {/*<div className={'duration-block'}>*/}
        {/*    <Input size={'small'} value={duration.minutes()}/>*/}
        {/*    <small>Minuten</small>*/}
        {/*</div>*/}
    </div>
}

export default DurationInput;
