import * as FoldersActions from "../../Folders/FoldersActions";
import {Button, Checkbox, Input, Popover} from "antd";
import React, {useState, useCallback, useEffect} from "react";
import DurationInput from "../../../Components/Duration/DurationInput";

const CreateSubfolderButton = ({dispatch, folder}) => {

    let [visible, setVisible] = useState(false);
    let save = () => {
        if (name.length === 0)
            return;
        dispatch(FoldersActions.createFolder(folder.id, name, "Desc", autoDelete && duration));
        setVisible(false);
        setName('');
    }

    let [name, setName] = useState("");
    let [autoDelete, setAutoDelete] = useState("");
    let [duration, setDuration] = useState("");

    return <Popover
        trigger={'click'}
        title={'Neuer Ordner'}
        visible={visible}
        onVisibleChange={setVisible}
        content={(
            <div>
                <small>Ordnername</small>
                <Input value={name}
                       autoFocus={true}
                       onPressEnter={save}
                       onChange={(e) => setName(e.target.value)} title={"Name"}/>

                <br/><br/>
                <Checkbox value={autoDelete} onChange={e => {
                    setAutoDelete(e.target.checked);
                }}>Dateien automatisch löschen</Checkbox>
                <br/>
                <small>nach</small>
                <DurationInput disabled={!autoDelete} value={duration} onChange={v => {
                    console.log("New Duration: ", v);
                    setDuration(v);
                }}/>
                <br/>
                <Button disabled={name.length === 0} onClick={save}>Speichern</Button>
            </div>
        )}
    >

        <Button size={'small'} icon={'folder-add'}/>
    </Popover>

};
export default CreateSubfolderButton
